<template>
  <div
    :style="getElementStyle"
    class="eb-select"
  >
          <!-- Below code is for Before Field View -->

  <div v-if="!isDefalutPos">
    <div style="display: flex; align-items: unset;">
      <label for="text" style="white-space: nowrap; margin-right: 5px;">{{ data.label }}
        </label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold; margin-right: 5px;"
        >
          *
        </span>
        <span style="margin-right: 5px;">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span>
        <span>
          <el-select
          :style="getStyle"
          v-model="selectedValue"
          :placeholder="data.placeholder"
          v-if="!isList"
          multiple
        >
          <el-option
            v-for="(option, index) of options"
            :value="option"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          multiple
          v-if="isList"
          :style="getStyle"
          v-model="selectedValue"
          :placeholder="data.placeholder"
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :label="option.name"
            :key="index"
          ></el-option>
        </el-select>
        </span>
    </div>
    <div
        v-if="isActive || isDataTableField"
        class="setting-icon"
        style="padding-left: 10px"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </div>
  </div>
                <!-- Below code is for Top Field View -->

    <el-row align="middle" v-else>
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24" 
        class="label-size"
      >
        <label for="text">{{ data.label }}
        </label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        <!-- </el-col>
      <el-col :span="showLabel && isDefalutPos ? 4 : 0" class="icon-size"> -->
        <!-- <i class="el-icon-info" :title="data.description"></i> -->
      </el-col>
      <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
        style="padding-left: 10px"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24">
        <el-select
          :style="getStyle"
          v-model="selectedValue"
          :placeholder="data.placeholder"
          v-if="!isList"
          multiple
        >
          <el-option
            v-for="(option, index) of options"
            :value="option"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          multiple
          v-if="isList"
          :style="getStyle"
          v-model="selectedValue"
          :placeholder="data.placeholder"
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :label="option.name"
            :key="index"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <!--<div>
      {{data.label}}
      <span v-if="data.description">
        <i class="el-icon-info" :title="data.description" />
      </span>
      <span v-if="isActive" class="setting-icon" @click="openSettings">
        <i class="el-icon-s-tools" />
      </span>
    </div>
    <div>
      <el-select
        :style="getStyle"
        v-model="selectedValue"
        :placeholder="data.placeholder"
        v-if="!isList"
      >
        <el-option v-for="(option,index) of options" :value="option" :key="index"></el-option>
      </el-select>
      <el-select
        v-if="isList"
        :style="getStyle"
        v-model="selectedValue"
        :placeholder="data.placeholder"
      >
        <el-option
          v-for="(option,index) of options"
          :value="option.value"
          :key="index"
          :label="option.name"
        ></el-option>
      </el-select>
    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: ["data", "value", "isActive", "isSelectView", "isDataTableField"],
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    //gayatri starts
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    }
    //end
  },
  data() {
    return {
      validations: [],
      options: [],
      isList: false,
      isDefalutPosq: true,
      showLabel: true
    };
  },
  mounted() {
    this.options = this.data.options || [];
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    } else {
      this.isList = false;
    }
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "LIST") {
          this.isList = true;
          this.options = this.getGlobalVariableById.list_data;
          this.data.is_list = true;
        } else {
          this.options = this.getGlobalVariableById.options;
          this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    }
  },

  watch: {
    "data.is_global_variable": {
      immediate: true,
      handler(val, oldVal) {
        if (val && !oldVal && this.data.global_variable_id) {
          this.setGlobalVariableData();
        }
        if (!val) {
          this.options = this.data.options || [];
          this.isList = false;
        }
      }
    },
    isActive: {
      immediate: true,
      handler(val, oldVal) {
        if (this.isSelectView) {
          this.options = this.data.options || [];
        }
        console.log(val, oldVal);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;

  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>
