<template>
  <div>
    <div class="icon-block">
      <el-row v-if="isActive|| isDataTableField">
        <el-col :span="3">
          <span
            class="setting-icon"
            @click="openSettings"
          >
            <i class="el-icon-s-tools" />
          </span>
        </el-col>
      </el-row>

      <el-col :span="showLabel && !isDefalutPos ? 8 : data.description ? 20 : 24">
        <label for="text"> {{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        <span>
          <div>
        <el-checkbox-group
          v-model="localValue"
          :change="changeField"
          :max="data.max_selection || undefined"
        >
              <ol :class="data.view_type === true ? '' : 'horizontalView'">
                <li
                  v-for="(option, index) of options"
                  :key="index"
                  :class="data.view_type === true ? 'checkbox-option' : 'horizontalView checkbox-option'"
                >
                  <el-checkbox
                    :value="option"
                    :label="option"
                    border
                  >
                    {{ option }}
                  </el-checkbox>
                </li>
              </ol>
        </el-checkbox-group>
        <p
          v-if="isView"
          :style="getStyle"
        >
          <template v-if="form && form[data.key] && form[data.key].length"> {{ form[data.key].join(',') }} </template>
          <template v-else>-</template>
        </p>
      </div>
        </span>
      </el-col>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: [
    "data",
    "value",
    "isActive",
    "fieldsData",
    "isView",
    "isDataTableField"
  ],
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    getCheckBoxStyle() {
      return `font-size:${this.data.height - 10}px;}`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    }
  },
  data() {
    return {
      validations: [],
      options: [],
      isDefalutPos: true,
      showLabel: true,
      localValue: [],
      max: 2
    };
  },
  mounted() {
    this.options = this.data.options || [];
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }

    this.max = this.data.max_selection || this.options.length;
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    openSettings() {
      this.$emit("settings");
    },
    changeField() {
      this.$set(this.form, this.data.key, !this.form[this.data.key]);
      console.log("datadtdatdatd", this.form, this.data);
    }
  },
  watch: {
    "data.options": {
      handler() {
        this.options = this.data.options || [];
      }
    }
  }
};
</script>

<style lang="scss">
.checkbox-option {
  margin-bottom: 10px; /* Adjust the space between options at the bottom */
}
// .el-checkbox-group {
//   font-size: 0;
//   display: flex;
//   flex-wrap: wrap;
// }
// .check {
//   margin-top: -2px !important;
// }
.GroupBox {
  height: 74px !important;
}
.horizontalView{
  display: flex;
  margin-right: 10px;;
}
</style>
