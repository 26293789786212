import { render, staticRenderFns } from "./FormulaView.vue?vue&type=template&id=630a90cf"
import script from "./FormulaView.vue?vue&type=script&lang=js"
export * from "./FormulaView.vue?vue&type=script&lang=js"
import style0 from "./FormulaView.vue?vue&type=style&index=0&id=630a90cf&prod&lang=scss"
import style1 from "./FormulaView.vue?vue&type=style&index=1&id=630a90cf&prod&lang=scss&scope=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports