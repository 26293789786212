<template>
  <div  :style="getElementStyle">
    <div v-if="!isDefalutPos" style="display: flex; align-items: center;">
        <div style="white-space: nowrap;">
          <label for="text">{{ data.label }}
          <span
            v-if="checkIsRequired"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <span style="margin-right: 5px;">
            <i
            class="el-icon-info"
            :title="data.description"
            ></i>
          </span>
        </label>
        </div>
        <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
        style="padding-left: 10px"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24" style="display: contents;">
        <el-select
          :style="getStyle"
          v-model="selectedValue"
          :placeholder="data.placeholder"
          v-if="!isList"
          :suffix-icon="data.properties.el_icon"
          clearable
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :label="option.name"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          v-if="isList"
          :style="getStyle"
          v-model="selectedValue"
          :placeholder="data.placeholder"
          :suffix-icon="data.properties.el_icon"
          clearable
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :label="option.name"
            :key="index"
          ></el-option>
        </el-select>
      </el-col>
    </div>

    <el-row align="middle" v-else>
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        class="label-size"
      >
        <label for="text">{{ data.label }}
          <span
            v-if="checkIsRequired"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
          <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        </label>
      </el-col>
      <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
        style="padding-left: 10px"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24">
        <el-select
          :style="getStyle"
          v-model="selectedValue"
          :placeholder="data.placeholder"
          v-if="!isList"
          :suffix-icon="data.properties.el_icon"
          clearable
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :label="option.name"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          v-if="isList"
          :style="getStyle"
          v-model="selectedValue"
          :placeholder="data.placeholder"
          :suffix-icon="data.properties.el_icon"
          clearable
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :label="option.name"
            :key="index"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["data", "value", "isActive", "isDataTableField"],
  data() {
    return {
      validations: [],
      options: [
        { name: "Monday", value: "MON" },
        { name: "Tuesday", value: "TUE" },
        { name: "Wednesday", value: "WED" },
        { name: "Thursday", value: "THU" },
        { name: "Friday", value: "FRI" },
        { name: "Saturday", value: "SAT" },
        { name: "Sunday", value: "SUN" }
      ],
      isList: false,
      // isDefalutPosq: true,
      showLabel: true,
      isDefalutPos: true,
      selectedValue: []
    };
  },
  computed: {
    getStyle() {
      return `height:${this.data.height - 30}px;`;
      //  width: ${
      //   this.data.width
      // }px !important;`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      borderStyle = borderStyle + `width:${this.data.width}px !important;`;
      return borderStyle;
    },
    checkIsRequired() {
      if (this.data.allow_multiple) {
        if (this.data.min_value > 0) {
          return true;
        }
        return false;
      } else {
        return this.data?.validations?.required;
      }
    }
  },
  mounted() {
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    openSettings() {
      this.$emit("settings");
    }
  }
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;

  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>
