export default {
    data() {
        return {}
    },
    computed: {
        getElementStyle() {
            let borderStyle = "";
      
            if (this.data.styles) {
              let type = this.data.styles.border_type
                ? this.data.styles.border_type
                : "solid";
              let size = this.data.styles.border_size
                ? this.data.styles.border_size + "px"
                : "0px";
              let color = this.data.styles.border_color
                ? this.data.styles.border_color
                : "";
              let font_size =
                this.data.styles &&
                this.data.styles.font &&
                this.data.styles.font.font_size
                  ? this.data.styles.font.font_size
                  : "";
              let bold =
                this.data.styles &&
                this.data.styles.font &&
                (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
                  ? "bold"
                  : "";
              let italic =
                this.data.styles &&
                this.data.styles.font &&
                (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
                  ? "italic"
                  : "";
      
              borderStyle = "border:" + type + " " + " " + size + " " + color;
      
              borderStyle += ";";
              borderStyle += this.data.styles.background
                ? `background: ${this.data.styles.background}`
                : "";
              borderStyle += ";";
              borderStyle += this.data.styles.label_color
                ? `color: ${this.data.styles.label_color}`
                : "";
              borderStyle += ";";
              borderStyle += "font-size:" + font_size + "px;";
              if (bold) {
                borderStyle += "font-weight:" + bold + ";";
              }
              if (italic) {
                borderStyle += "font-style:" + italic + ";";
              }
              borderStyle += this.data.styles.label_color
                ? `color: ${this.data.styles.label_color}`
                : "";
            }
      
            return borderStyle;
          },
          isDefalutPos() {
                if (this.data.styles && this.data.styles.labelStyle == "right") {
                return false;
                } else {
                return true;
                }
            }
    },
    methods: {

    }
}